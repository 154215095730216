import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyAsUdOrKHu5mY8Db2UqOk3Q9mwTvZGZA68",
    authDomain: "dj-jc-mobile-app.firebaseapp.com",
    databaseURL: "https://dj-jc-mobile-app-default-rtdb.firebaseio.com",
    projectId: "dj-jc-mobile-app",
    storageBucket: "dj-jc-mobile-app.appspot.com",
    messagingSenderId: "11475829608",
    appId: "1:11475829608:web:f6bafd3adeb804dc544cdd",
    measurementId: "G-2FYG93WSRM"
};

firebase.initializeApp(config);

export default firebase.database();