<template>
  <div id="app">
    <!-- <component v-bind:is="header"/> -->
    <headernav v-if="shouldShowHeader" />
    <div class="mt5 mb5 min-height">
      <router-view/>
    </div>
    <Footer v-if="shouldShowFooter" />
    <AudioPlayer v-if="shouldShowAudioPlayer" />
  </div>
</template>

<script>
import headernav from "./components/layout/nav.vue";
import AudioPlayer from "./components/layout/audioplayer.vue";
import Footer from "./components/layout/footer.vue";

export default {
  name: "App",
  components: {
    headernav,
    Footer,
    AudioPlayer,
  },
  data() {
    return {
      shouldShowHeader: true,
      shouldShowFooter: true,
      shouldShowAudioPlayer: true,
      shouldShowRouter:true,
    };
  },
  
  watch: {
    $route(to, from) {
      console.log('Navigating from', from.fullPath, 'to', to.fullPath);
      this.updateVisibility();
    },
  },
  methods: {
    updateVisibility() {
      // Set visibility based on the current route
      const isSplashVideoRoute = this.$route.name.toLowerCase() === 'splashvideo';
      this.shouldShowHeader = !isSplashVideoRoute;
      this.shouldShowFooter = !isSplashVideoRoute;
      this.shouldShowAudioPlayer = !isSplashVideoRoute;
      this.shouldShowRouter = !isSplashVideoRoute;
      //alert(this.shouldShowRouter);
    },
  },
  created(){
    this.updateVisibility();
  },
  beforeRouteEnter(to, from, next) {
    console.log('Navigating from', from.fullPath, 'to', to.fullPath);
    
    next(vm => {
      vm.updateVisibility();
    });
  },
  beforeRouteUpdate(to, from, next) {
    console.log('Navigating from', from.fullPath, 'to', to.fullPath);
    this.updateVisibility();
    next();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
